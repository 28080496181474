@use "./../styles/variables.module.scss" as *;
@use "./../styles/font.mixins" as *;

.button {
  --button-background-color: var(--button-background-color-override, var(--primary-color));
  --button-font-color: var(--button-font-color-override, var(--white));
  --button-border-color: var(--button-border-color-override, var(--primary-color));

  display: inline-block;
  box-sizing: border-box;
  position: relative;
  // all: revert; - whats this?
  @include FontSecondaryRegular;
  color: var(--button-font-color);
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  border-radius: var(--input-button-border-radius);
  transition: background-color 0.3s ease;
  cursor: pointer;
  font-size: var(--button-font-size);
  line-height: 18px;
  text-decoration: none;
  text-align: center;

  .uppercase {
    text-transform: uppercase;
  }

  @include spacingTopBottom(2);
  @include spacingLeftRight(8);

  &:focus {
    outline: none;
  }

  &.spacingSmall {
    @include spacingLeftRight(3);
  }

  &:hover {
    background-color: var(--primary-hover-color);

    * {
      color: $white;
    }
  }

  &[disabled] {
    background-color: $lightGrey;
    border-color: $lightGrey;
    color: $grey;
    cursor: not-allowed;

    &:hover {
      * {
        color: $grey;
      }
    }
  }

  &.bordered {
    background-color: $white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);

    * {
      color: var(--primary-color);
    }

    &[disabled] {
      border-color: $lightGrey;
      cursor: not-allowed;

      * {
        color: $grey;
      }

      &:hover {
        border-color: $lightGrey;

        * {
          color: $grey;
        }
      }
    }

    &:hover {
      border-color: var(--primary-hover-color);

      * {
        color: var(--primary-hover-color);
      }
    }
  }

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.small {
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: var(--input-button-border-radius);

    * {
      font-size: var(--font-size-text);
    }
  }

  .buttonLoader {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%) scale(0);
    transform-origin: center center;
    transition: all 0.3s ease;
  }

  &.loading {
    .buttonLoader {
      transform: translateY(-50%) scale(0.7);
    }
  }

  &.ghost {
    background-color: var(--white);
    color: var(--primary-color);

    &:hover {
      color: var(--primary-hover-color);

      * {
        color: var(--primary-hover-color);
      }
    }
  }
}
