@use './../styles/variables.module.scss' as *;
@use "./../styles/font.mixins" as *;

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Hide and show pin in the loader
@keyframes blink {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.digandoLoaderBox {
  position: relative;
  width: 26px;
  height: 26px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;

  &.digandoLoaderSmall {
    width: 16px;
    height: 16px;
  }
}

.digandoLoader {
  display: inline-block;
  width: 100%;
  height: 100%;

  animation: rotate 1.5s infinite linear;

  .digandoLoaderSmall & {
    i {
      width: 5px;
      height: 5px;
    }
  }

  i {
    display: block;
    width: 10px;
    height: 10px;
    background-color: var(--primary-color);

    border-radius: 100%;

    animation: blink 1.2s infinite linear;

    &:nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.3;

      inset-inline-start: 0;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.525;

      inset-inline-end: 0;
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.74;

      inset-inline-end: 0;
      animation-delay: 0.8s;
    }

    &:nth-child(4) {
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: 1;

      inset-inline-end: 0;
      animation-delay: 1.2s;
    }
  }
}

.digandoLoaderInvert {
  i {
    background-color: var(--white);
  }
}
