@font-face {
  font-family: 'Goldbeck';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(/themes/goldbeck/fonts/GoldbeckNext-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Goldbeck';
  font-style: italic;
  font-display: block;
  font-weight: 300;
  src: url(/themes/goldbeck/fonts/GoldbeckNext-LightItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Goldbeck';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(/themes/goldbeck/fonts/GoldbeckNext-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Goldbeck';
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url(/themes/goldbeck/fonts/GoldbeckNext-SemiBold.woff2) format('woff2');
}
