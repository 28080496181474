@use '@digando/common-ui/scss/variables' as *;
@use '@digando/common-ui/scss/fonts' as *;

.loggedInMenu {
  background-color: var(--white);
  color: var(--black);
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%) translateX(25%);
  z-index: 110;
  box-shadow: var(--overlay-shadow);
  padding: 0;
  margin: 0;
  border-radius: 4px;
  overflow: hidden;
  width: 275px;
  max-width: 100vw;

  li {
    * {
      @include FontPrimaryRegular;
    }

    &:hover {
      color: var(--primary-color);
      background-color: var(--background-light);
    }
  }

  a {
    display: block;
    @include spacingTopBottom(1);
    @include spacingLeftRight(1.5);
    font-size: var(--font-size-text);
    line-height: 20px;
    transition: color 0.3s ease;

    :hover {
      color: var(--primary-color);
    }
  }

  .logoutButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
    @include spacingRight(1);
    background-color: var(--background-light) !important;

    span,
    svg {
      transition: fill 0.3s ease;
    }

    &:hover {
      svg {
        fill: var(--primary-color);
      }
    }
  }

  .userContainer {
    display: block;
  }

  .logout {
    display: block;
  }

  .user {
    display: block;
    font-size: var(--font-size-text-small);
  }
}

.widgetDescription {
  display: none !important;

  @media all and (min-width: 1186px) {
    display: inline-block !important;
  }
}
