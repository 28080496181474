@font-face {
  font-family: 'DBScreenSans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/themes/dbinfrago/fonts/DBScreenSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'DBScreenSans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(/themes/dbinfrago/fonts/DBScreenSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'DBScreenHead';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/themes/dbinfrago/fonts/DBScreenHead-Regular.woff) format('woff');
}

@font-face {
  font-family: 'DBScreenHead-Light';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/themes/dbinfrago/fonts/DBScreenHead-Light.woff) format('woff');
}

@font-face {
  font-family: 'DBScreenHead-Light';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/themes/dbinfrago/fonts/DBScreenHead-Light.woff) format('woff');
}
