@use "./../styles/variables.module.scss" as *;
@use "./../styles/font.mixins" as *;

.tagItem {
  display: inline-block;

  @include spacingLeftRight(1);
  @include spacingBottom(1);
}

.tagItems {
  display: flex;
}

.tagText {
  font-size: 12px;
  text-align: left;
  line-height: 25px;
  color: $black;
  @include FontPrimaryRegular;
  @include spacingLeftRight(1);
}

.tag {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  vertical-align: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  @include spacingTopBottom(1);
  @include spacingLeftRight(2);
  border: none;
  border-radius: var(--input-button-border-radius);
  cursor: pointer;
  white-space: nowrap;
  margin-right: spacingCalculator(1);
  margin-bottom: spacingCalculator(1);

  &:last-of-type {
    margin-right: spacingCalculator(0);
  }

  svg {
    font-size: 17px;
    line-height: 17px;
    white-space: nowrap;
  }

  span:first-of-type {
    display: block;
    @include spacingRight(2);
    @include FontPrimaryRegular;
    vertical-align: middle;
    color: $white;
    font-size: 17px;
    line-height: 17px;
    width: auto;
    text-align: left;
  }
}
