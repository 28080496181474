@use "./../../styles/variables.module.scss" as *;
@use "./../../styles/font.mixins" as *;

.option {
  padding: 8px 16px;
  cursor: pointer;

  color: $greyTypo;

  @include FontPrimaryRegular;
  font-size: 17px;
  line-height: 22px;

  &.active {
    background-color: #eee;
  }
}
