@use '@digando/common-ui/scss/variables' as *;

.sidebarContainer {
  --sidebar-background-color: var(--page-sidebar-background-color);

  @media print {
    display: none;
    visibility: hidden;
  }

  position: fixed;
  z-index: 111;
  top: 0;
  right: 0;
  width: 100vw;
  max-width: 600px;
  height: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  background-color: var(--sidebar-background-color);
  box-shadow: -0.2em 0 0.2em rgba(0, 0, 0, 0.3);
  transform: translateX(102%);
  transition: transform 0.3s ease;
  overflow: scroll;
  overflow-x: hidden;

  &.isOpen {
    transform: translateX(0%);
  }

  .sidebarContent {
    @include spacingAround(4);
  }
}
