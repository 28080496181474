@mixin FontPrimaryRegular {
  font-family: var(--font-primary-regular-family), sans-serif;
  font-weight: var(--font-primary-regular-weight);
  font-style: var(--font-primary-regular-style);
}

@mixin FontPrimaryBold {
  font-family: var(--font-primary-semi-bold-family), sans-serif;
  font-weight: var(--font-primary-semi-bold-weight);
  font-style: var(--font-primary-semi-bold-style);
}

@mixin FontSecondaryLight {
  font-family: var(--font-secondary-light-family),  sans-serif;
  font-weight: var(--font-secondary-light-weight);
  font-style: var(--font-secondary-light-style);
}

@mixin FontSecondaryRegular {
  font-family: var(--font-secondary-regular-family), sans-serif;
  font-weight: var(--font-secondary-regular-weight);
  font-style: var(--font-secondary-regular-style);
}

@mixin FontSecondaryRegularItalic {
  font-family: var(--font-secondary-regular-italic-family), sans-serif;
  font-weight: var(--font-secondary-regular-italic-weight);
  font-style: var(--font-secondary-regular-italic-style);
}

@mixin FontSecondarySemiBold {
  font-family: var(--font-secondary-semi-bold-family), sans-serif;
  font-weight: var(--font-secondary-semi-bold-weight);
  font-style: var(--font-secondary-semi-bold-style);
}
