@use '../styles/variables.module' as *;
@use '../styles/font.mixins' as *;
@use './input-base' as *;

.textareaContainer {
  @extend .inputBaseContainer;

  textarea {
    @extend .inputBase;
    resize: vertical;
    min-height: calc(var(--input-font-size) * 1.2 * var(--ta-height) + 2* #{spacingCalculator(1.5)} ); // 4 lines height
  }
}
