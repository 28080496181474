$spacingWhiteList: 0, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 10, 14;

@function spacingCalculator($multiplier) {
  @if (index($spacingWhiteList, $multiplier) == null) {
    @error "Only spacing with sizes #{$spacingWhiteList} are allowed.";
  }

  $spacingSteps: 4;

  @return #{calc($multiplier * var(--spacing-unit))};
}

@mixin spacingTop($multiplier: 1) {
  padding-top: spacingCalculator($multiplier);
}

@mixin spacingBottom($multiplier: 1) {
  padding-bottom: spacingCalculator($multiplier);
}

@mixin spacingLeft($multiplier: 1) {
  padding-left: spacingCalculator($multiplier);
}

@mixin spacingRight($multiplier: 1) {
  padding-right: spacingCalculator($multiplier);
}

@mixin spacingTopBottom($multiplier: 1) {
  @include spacingTop($multiplier);
  @include spacingBottom($multiplier);
}

@mixin spacingLeftRight($multiplier: 1) {
  @include spacingLeft($multiplier);
  @include spacingRight($multiplier);
}

@mixin spacingAround($multiplier: 1) {
  @include spacingTop($multiplier);
  @include spacingBottom($multiplier);
  @include spacingLeft($multiplier);
  @include spacingRight($multiplier);
}

@mixin spacingUpSide($multiplierTopBottom: 1, $multiplierLeftRight: 1) {
  @include spacingTop($multiplierTopBottom);
  @include spacingBottom($multiplierTopBottom);
  @include spacingLeft($multiplierLeftRight);
  @include spacingRight($multiplierLeftRight);
}







// Colors
$primaryColor: var(--primary-color);
$primaryHoverColor: var(--primary-hover-color);
$primaryActiveColor: #ff483e;
$errorColor: #e7392f;
$petrol: var(--secondary-color);
$black: var(--black);
$blackTypo: #000000;
$grey: #828282;
$greyTypo: #595959;
$lightGrey: #bdbdbd;
$white: var(--white);
$green: #27ae60;
$backgroundLight: #e6edee;
$backgroundGrey: #eeeeee;
$redLight: 'rgba(231, 57, 47, 0.1)';
$red: #e7392f;
$textColorHoverTransition: "color 0.2s ease, fill 0.2s ease";

// Breakpoints
$mobile: 425px;
$tablet: 768px;
$laptop: 1024px;
$laptopL: 1240px;
$desktop: 1600px;

$mapBreakpoint: '660px';

// Font-Sizes
$fontSizeForms: 17px;
$fontSizeH3: 24px;
$fontSizeButtonPrimary: 20px;
$fontSizeLabelActive: 14px;

// Font-Families
$fontFamilyStandard: 'Barlow', sans-serif;
$fontFamilyHeaders: 'Barlow Condensed', sans-serif;

// Paddings
$paddingBlocksTop: 12px;
$paddingBlocksBottom: 12px;
$paddingBlocksLeft: 8px;

// Sizes
$mapHeight: 500px;

// to get this variables in the theme.tsx
:export {
  primaryActiveColor: $primaryActiveColor;
  errorColor: $errorColor;
  red: $red;
  petrol: $petrol;
  black: $black;
  grey: $grey;
  greyTypo: $greyTypo;
  lightGrey: $lightGrey;
  white: $white;
  green: $green;
  backgroundLight: $backgroundLight;
  backgroundGrey: $backgroundGrey;
  redLight: $redLight;
  textColorHoverTransition: $textColorHoverTransition;
}
