@use '@digando/common-ui/scss/variables' as *;
@use './styles/widget-container.scss' as *;

.pageHeaderContainer {
  position: sticky;
  z-index: 105;
  top: 0;
  left: 0;
  width: 100%;
  color: var(--page-header-font-color);

  @media print {
    height: 100px;
  }

  @media print {
    position: relative;
  }

  .inner {
    background-color: var(--page-header-background-color);

    @media print {
      background-color: transparent;
    }
  }

  .menuRight {
    margin-right: 0;
    left: spacingCalculator(2);
    display: flex;
    justify-content: center;

    @media all and (min-width: $laptop) {
      display: none;
    }

    @media print {
      display: none;
    }
  }

  .menuLeft {
    display: none;

    @media all and (min-width: $laptop) {
      display: flex;
    }

    @media print {
      display: none;
    }
  }

  .centerItem {
    display: none;
    flex-shrink: 0;
    flex-grow: 0;

    @media all and (min-width: $tablet) {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
    }
  }

  .logoInnerDesktop {
    display: none;

    @media all and (min-width: 1800px) {
      display: inline-block;
      padding-right: spacingCalculator(1);
      color: var(--page-header-font-color);
    }
  }

  .logoInnerLaptop {
    color: var(--page-header-font-color);

    @media all and (min-width: 1800px) {
      display: none;
    }
  }

  .logoWrapperDesktop {
    max-height: 30px;
    display: none;

    @media all and (min-width: $laptop) {
      display: flex;
      margin-top: 0;
      padding-left: spacingCalculator(4);

      img {
        max-width: fit-content;
        height: 30px;
        vertical-align: bottom;
      }
    }

    @media print {
      padding-left: 0;
    }
  }

  .logoWrapperMobile {
    line-height: 1;

    img {
      width: auto;
      height: 20px;
      vertical-align: bottom;
    }

    @media all and (min-width: $laptop) {
      display: none;
    }

    @media print {
      padding-left: 0;
    }
  }

  .logoMobile {
    line-height: 1;

    img {
      width: auto;
      height: 20px;
      vertical-align: bottom;
    }

    @media all and (min-width: $laptop) {
      display: none;
    }

    @media print {
      padding-left: 0;
    }
  }

  .leftContainer {
    display: flex;
    align-items: center;

    @media all and (min-width: $laptop) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  .rightContainer {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    flex-basis: 0;
  }

  .centerItemContainerDesktop {
    &.isDelayedComponent {
      display: none !important
    }

    @media all and (min-width: $laptop) {
      display: flex;
      justify-content: center;
    }
  }

  .globalSearchWrapper {
    padding-left: spacingCalculator(2);
    padding-right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    opacity: 1;
    pointer-events: all;
    transition: height 300ms, opacity 300ms;

    &.mainSearchIsVisible {
      height: 0;
      opacity: 0;
      pointer-events: none;
      transition: height 350ms, opacity 350ms;
    }

    @media all and (min-width: $mobile) {
      padding-right: spacingCalculator(2);
    }

    @media all and (min-width: $tablet) {
      padding-left: spacingCalculator(1);
      height: 100%;
    }

    @media all and (min-width: $laptop) {
      padding-right: spacingCalculator(0);
      padding-left: spacingCalculator(0);
    }
  }

  .topBar {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--black);
    height: auto;
    padding-bottom: spacingCalculator(1);
    padding-top: spacingCalculator(1);
    padding-left: 16px;
    padding-right: 16px;

    @media all and (max-width: 361px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media all and (max-width: 321px) {
      padding-left: 6px;
      padding-right: 6px;
    }

    div {
      @media all and (max-width: 450px) {
        margin-left: 0;
      }
    }

    @media all and (max-width: 450px) {
      justify-content: space-between;
    }

    @media all and (min-width: $tablet) {
      display: none;
    }

    @media print {
      display: none;
    }
  }

  .pageHeaderInner {
    display: flex;
    align-items: center;
    padding-right: env(safe-area-inset-right, 2.5vw);
    padding-left: env(safe-area-inset-left, 2.5vw);
    max-width: 95vw;
    margin-left: auto;
    margin-right: auto;
    padding-top: spacingCalculator(2);
    padding-bottom: spacingCalculator(2);
    gap: spacingCalculator(2);

    @media all and (min-width: $tablet) {
      height: var(--page-header-height-desktop);
    }

    @media all and (min-width: $desktop) {
      display: flex;
      justify-content: space-between;
    }
  }
}

.availabilityCheckContainer {
  @extend .pageHeaderWidgetContainer;
  display: none;

  @media all and (min-width: 1320px) {
    padding-right: spacingCalculator(4);
    display: block;
  }

  @media all and (min-width: 2000px) {
    padding-right: spacingCalculator(0);
    margin-left: spacingCalculator(3);
  }

  @media print {
    display: none;
  }
}
