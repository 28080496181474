@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/themes/grotemeier/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(/themes/grotemeier/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/themes/grotemeier/fonts/Inter-SemiBold.ttf);
}

@font-face {
  font-family: 'Inter Tight';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/themes/grotemeier/fonts/InterTight-Regular.ttf);
}

@font-face {
  font-family: 'Inter Tight';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/themes/grotemeier/fonts/InterTight-SemiBold.ttf);
}
