@use './../styles/variables.module.scss' as *;
@use "./../styles/font.mixins" as *;
@use './input-base.scss' as *;
@use './date-input-mobile.scss' as *;

.inputGroup2 {
  margin-bottom: spacingCalculator(2);

  &.noBottomSpacing {
    margin-bottom: 0;
  }
}

.inputGroup4 {
  margin-bottom: spacingCalculator(4);

  &.noBottomSpacing {
    margin-bottom: 0;
  }
}

.inputGroup6 {
  margin-bottom: spacingCalculator(6);

  &.noBottomSpacing {
    margin-bottom: 0;
  }
}

.inputGroup8 {
  margin-bottom: spacingCalculator(8);

  &.noBottomSpacing {
    margin-bottom: 0;
  }
}

.inputGroup14 {
  margin-bottom: spacingCalculator(14);

  &.noBottomSpacing {
    margin-bottom: 0;
  }
}

.inputGroup12 {
  margin-bottom: spacingCalculator(3);

  &.noBottomSpacing {
    margin-bottom: 0;
  }
}

.inputContainer {
  --textfield-background-color: var(--input-background);
  --textfield-border: 1px solid var(--border-light-color);
  --textfield-border-radius: var(--input-text-border-radius);
  --textfield-font-size: var(--font-size-text);

  position: relative;
  display: flex;
  align-items: flex-end;
  height: 48px;
  background-color: var(--textfield-background-color);
  border: var(--textfield-border);
  border-radius: var(--textfield-border-radius);

  .input {
    cursor: pointer;
    position: relative;
    bottom: 0;
    background-color: transparent;
    border: none;
    font-size: var(--textfield-font-size);
    line-height: 27px;
    @include spacingLeftRight(1);
    @include spacingBottom(0.5);
    width: 100%;
    text-align: left;

    &[type="time"]::-webkit-calendar-picker-indicator,
    &[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    &.native {
      &[type="date"]::-webkit-calendar-picker-indicator,
      &[type="time"]::-webkit-calendar-picker-indicator {
        display: initial;
        -webkit-appearance: initial;
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }

      & *[type="time"],
      & *[type="date"] {
        -moz-appearance: textfield;
      }
    }

    &:focus {
      border: none;
      outline: none;
    }
  }

  .label {
    @extend .baseLabelAsPlaceholder;

    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    align-items: center;
  }


  &.hidden {
    display: none;
  }

  &.hasValue,
  &:focus-within {
    .label {
      @extend .baseLabelAsLabel;

      /* @todo: Check how this can be removed */
      :global(.goldbeck-theme) & {
        @include spacingTop(1);
      }
    }
  }

  &.hasError {
    border-color: var(--color-error);
    margin: 0;

    .label {
      color: var(--color-error);
    }
  }

  .loader {
    width: 30px;
    align-self: center;
  }

  .icon {
    width: 30px;
    align-self: center;
    font-size: 0;
    line-height: 0;
    margin-right: spacingCalculator(0.5);
    cursor: pointer;

    &:last-of-type {
      margin-right: spacingCalculator(1);
    }
  }
}

.error {
  @extend .errorContainer ;
  bottom: -#{spacingCalculator(1)};
}
