@use 'sass:color';

:root {
  --test-color: green;
}

:root .goldbeck-theme {
  /*********************************************************************/
  /* NAMED TENANT COLORS                                               */
  /* This color names are configured by the tenant's CI/CD             */
  /* and should be used to define the plain color values in CSS format */
  /*********************************************************************/
  --theme-brand-color: #3EBAEC;
  --theme-brand-color-light: #3EBAEC24; // 14% opacity
  --theme-onyx: #27292B;
  --theme-pearl: #FEFEFE;
  --theme-carbon: #2B323A;
  --theme-carbon80: #2B323ACC; // 80% opacity
  --theme-carbon66: #2B323AA8; // 66% opacity
  --theme-carbon50: #2B323A80; // 50% opacity
  --theme-carbon07: #2B323A12; // 7% opacity
  --theme-background: #EEEFF0;

  /*********************************************************************/
  /* Use only NAMED THEME COLORS to define next variable values        */
  /*********************************************************************/
  /* MAIN COLORS */
  --primary-color: var(--theme-brand-color);
  --primary-color-light: var(--theme-brand-color-light);
  --primary-hover-color: var(--theme-brand-color);
  --primary-active-color: var(--primary-color);
  --secondary-color: var(--theme-onyx);
  --secondary-background-color: var(--theme-carbon);
  --secondary-contrast-font-color: var(--theme-pearl);
  --white: var(--theme-pearl);
  --black: var(--theme-onyx);

  /*******************************************************/
  /* Use only MAIN COLORS to define next variable values */
  /*******************************************************/
  /* BACKGROUNDS */
  --app-background-color: var(--theme-background);
  --background-light: var(--theme-pearl);
  --background-dark: var(--theme-carbon);
  --input-background: var(--theme-background);
  --hint-background-color: var(--theme-brand-color-light);

  /* FONT COLORS */
  --font-color-light: var(--theme-carbon66);
  --font-color-medium: var(--theme-carbon80);
  --font-color-disabled: var(--theme-carbon50);

  /* ERRORS */
  --color-error: #E7392F;
  --color-error-background: #FDEBEA;

  /* WARNINGS */
  --color-warning-background: var(--theme-brand-color-light);

  /* SUCCESS */
  --color-success: #27AE60;

  /* BORDERS */
  --border-light-color: #BDBDBD;
  --border-disabled-color: #BDBDBD;

  /* Rounded corners */
  --container-border-radius: 8px;
  --input-button-border-radius: 16.5px;
  --input-checkbox-border-radius: 2px;
  --input-text-border-radius: 4px;
  --global-search-suggestions-border-radius: 28px;
  --global-search-bar-header-border-radius-mobile: 19px;
  --category-card-border-radius: 10px;

  /* SHADOWS */
  --overlay-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  --overlay-border-radius: 8px;

  /* FONTS */
  --font-primary-regular-family: "Goldbeck";
  --font-primary-regular-weight: 400;
  --font-primary-regular-style: normal;

  --font-primary-semi-bold-family: "Goldbeck";
  --font-primary-semi-bold-weight: 600;
  --font-primary-semi-bold-style: normal;

  --font-secondary-light-family: "Goldbeck";
  --font-secondary-light-weight: 300;
  --font-secondary-light-style: normal;

  --font-secondary-regular-family: "Goldbeck";
  --font-secondary-regular-weight: 400;
  --font-secondary-regular-style: normal;

  --font-secondary-regular-italic-family: "Goldbeck";
  --font-secondary-regular-italic-weight: 400;
  --font-secondary-regular-italic-style: italic;

  --font-secondary-semi-bold-family: "Goldbeck";
  --font-secondary-semi-bold-weight: 600;
  --font-secondary-semi-bold-style: normal;

  /* FONT-SIZES */
  --font-size-steps: 7px;
  // Mobile sizes
  --font-size-text-mobile: 17px;
  --font-size-text-small-mobile: 14px;
  --font-size-headline-h1-mobile: calc(4 * var(--font-size-steps));
  --font-size-headline-h2-mobile: calc(3.5 * var(--font-size-steps));
  --font-size-headline-h3-mobile: calc(2.5 * var(--font-size-steps));
  --font-size-headline-h4-mobile: calc(2.1 * var(--font-size-steps));
  --font-size-text-small-title-mobile: var(--font-size-headline-h4-mobile);
  // Mobile line heights
  --line-height-headline-h1-mobile: calc(4.7 * var(--font-size-steps));
  --line-height-headline-h2-mobile: calc(4.1 * var(--font-size-steps));
  --line-height-headline-h3-mobile: calc(3 * var(--font-size-steps));
  --line-height-headline-h4-mobile: calc(2.5 * var(--font-size-steps));

  // Non-mobile sizes
  --font-size-text: 13px;
  --font-size-text-small: 13px;
  --font-size-headline-h1: calc(4 * var(--font-size-steps));
  --font-size-headline-h2: calc(3 * var(--font-size-steps));
  --font-size-headline-h3: calc(2.5 * var(--font-size-steps));
  --font-size-headline-h4: calc(2 * var(--font-size-steps));
  --font-size-text-small-title: var(--font-size-headline-h4);
  // Non-mobile line heights
  --line-height-headline-h1: calc(4.5 * var(--font-size-steps));
  --line-height-headline-h2: calc(3.5 * var(--font-size-steps));
  --line-height-headline-h3: calc(3 * var(--font-size-steps));
  --line-height-headline-h4: calc(2.5 * var(--font-size-steps));

  /* TRANSITIONS */
  --text-hover-transition: color 0.2s ease, fill 0.2s ease;

  /* SPACING */
  --spacing-unit: 4px;

  @media all and (min-width: 1024px) {
    --spacing-unit: 7px;
  }


  /** OLD */
  --grey-typo: #595959;
  --spacing-steps: 4px;

  @media all and (min-width: 1024px) {
    --spacing-steps: 7px;
  }

  /*********************************************************************/
  /* COMPONENTS should be configured only in edge cases                */
  /*********************************************************************/
  /* COMPONENTS */
  /** Global search bar **/
  --global-search-bar-background: var(--background-light);
  --global-search-bar-radius: 1000px;
  --global-search-bar-padding-left: 16px;
  --global-search-bar-padding-right: 16px;
  --global-search-bar-padding-top-down: 8px;
  --global-search-bar-input-focus-border-radius: var(--input-text-border-radius);
  --global-search-button-radius: 50%;
  --global-search-button-radius-mobile: 22px;
  --global-button-side-length: 40px;

  /** Search header **/
  --search-header-background-color:var(--secondary-background-color);
  --search-header-font-color: var(--black);

  /** Search page **/
  --search-page-filter-header-font-size: var(--font-size-headline-h3);
  --search-page-filter-box-header-divider: none;

  /** Homepage **/
  --homepage-header-font-color: var(--black);
  --homepage-header-height: 630px;

  /** Page header **/
  --page-header-height: 77px;
  --page-header-background-color: var(--white);
  --page-header-height-desktop: 77px;
  --page-header-font-color: var(--black);
  --page-header-cart-color: var(--primary-color);
  --page-header-cart-font-color-contrast: var(--white);

  /** Page footerv**/
  --page-footer-background-color: var(--black);
  --page-footer-font-color: var(--white);

  /** Configurator **/
  --configurator-header-background-color: var(--app-background-color);
  --configurator-header-font-color: var(--font-color);
  --configurator-section-background-color: var(--white); // default: var(--background-light)
  --configurator-section-form-background-color: var(--white); // default: var(--background-light)
  --configurator-section-price-background-color: transparent;
  --configurator-section-specs-background-color: transparent;
  --configurator-header-font-size: var(--font-size-headline-h4);
  --configurator-title-font-size: var(--font-size-headline-h2);
  --configurator-subtitle-font-size: var(--font-size-headline-h2);
  --configurator-font-size: var(--font-size-text-small-title);
  --configurator-cta-button-background-color: var(--primary-color);
  --configurator-cta-button-font-color: var(--white);
  --configurator-cta-button-border-color: var(--primary-color);

  /** Basket **/
  --basket-billing-title-font-size: var(--font-size-headline-h1);
  --basket-billing-title-font-size-mobile: var(--font-size-headline-h4);
  --basket-summary-font-size: var(--font-size-headline-h4);
  --basket-summary-font-big: var(--font-size-headline-h3);

  /** Product cards **/
  --product-card-header-background-color: var(--theme-grey); // default: var(--background-light)
  --product-card-header-font-size: var(--font-size-headline-h3);

  /** Main navigation **/
  --navigation-container-background-color: var(--white);
  --navigation-header-font-color: var(--primary-color); // default: var(--primary-color)
  --navigation-background-color: var(--black); // default: var(--black)

  /** Category overview **/
  --category-overview-container-background-color: var(--app-background-color);
  --category-overview-card-header-border-bottom-color: var(transparent);
  --category-overview-card-header-text-color: var(--black);

  /** Sidebar **/
  --page-sidebar-background-color: var(--white);
  --page-sidebar-title-font-size: var(--font-size-headline-h3);

  /** Product page **/
  --product-page-cta-button-background-color: var(--primary-color);
  --product-page-cta-button-font-color: var(--white);
  --product-page-cta-button-border-color: var(--primary-color);

  /** Buttons **/
  --button-font-size: var(--font-size-headline-h4);

  /** Emails **/
  --email-background-color: var(--white);
  --email-body-color: var(--white);
  --email-text-color: var(--theme-carbon);
  --email-footer-background-color: var(--theme-carbon);
  --email-footer-text-color: var(--white);
  --email-delimiter-color: #73787D;
  --email-button-text-color: var(--white);
  --email-button-background-color: var(--primary-color);
  --email-table-row-color: #EFF0F0;

  /** Keycloak **/
  --keycloak-loader-color: var(--primary-color);

  --keycloak-header-background-color: rgba(234, 235, 235, 1);
  --keycloak-header-font-color: var(--black);

  --keycloak-footer-background-color: #343434;
  --keycloak-footer-font-color: #ffffff;
}
