@use '../styles/variables.module' as *;
@use '../styles/font.mixins' as *;

.dateInputMobileContainer {
  --input-background-color: var(--input-background);
  --input-border: 1px solid var(--border-light-color);
  --input-font-size: var(--font-size-text);
  --textfield-label-font-size: var(--font-size-text);
  --textfield-label-font-size-active: var(--font-size-text-small);
  --textfield-label-font-color: var(--font-color-light);
  --textfield-label-font-color-active: var(--secondary-color);
  position: relative;

  &.hasError {
    border-color: var(--color-error);
    margin: 0;

    .dateInputMobileLabel {
      color: var(--color-error);
    }
  }

  .dateInputMobile {
    display: block;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    white-space: nowrap;
    border-radius: 4px;
    background-color: var(--white);
    color: var(--black);
    text-align: left;
    @include spacingLeftRight(2);
    @include spacingTop(4);
    @include FontPrimaryRegular;
    font-size: var(--input-font-size);
    border: 1px solid var(--border-light-color);

    &[type="date"]::-webkit-calendar-picker-indicator {
      width: 30px;
      height: 30px;
      right: 7px;
      position: absolute;
      top: 50%;
      opacity: 0;
      transform: translateY(-50%);
    }

    &::-webkit-date-and-time-value {
      text-align: left;
    }

    &:focus {
      border: 1px solid var(--secondary-color);
      border-color: var(--secondary-color);
      outline: none;
    }
  }

  .dateInputMobileLabel {
    cursor: pointer;
    @include FontPrimaryRegular;
    color: var(--textfield-label-font-color-active);
    font-size: 14px;
    line-height: var(--textfield-label-font-size);
    position: absolute;
    z-index: 1;
    display: flex;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    @include spacingLeft(2);
    height: 26px;
  }

  .dateInputMobileIcon {
    position: absolute;
    pointer-events: none;
    color: var(--primary-color);
    background-color: var(--white);
    right: 12px;
    bottom: 4px;

    svg {
      fill: var(--primary-color);
    }

    * {
      pointer-events: none;
    }
  }
}