@use './variables.module.scss' as *;
@use './font.mixins.scss' as *;

// This file defines each of the typography styles used in the application, it is equivalent to the typography file in figma

// @todo: This headline is defined as h2 in figma, but it's defined as h3 in the variables file
.TypographyHeadline2 {
  @include FontSecondarySemiBold;
  font-size: var(--font-size-headline-h3-mobile);
  line-height: var(--line-height-headline-h3-mobile);

  @media all and (min-width: $laptop) {
    font-size: var(--font-size-headline-h3);
    line-height: var(--line-height-headline-h3);
  }
}

.TypographyCopy {
  font-size: var(--font-size-text);
  line-height: 22px; // @todo: This varable is missing in the variables file

  @media all and (min-width: $laptop) {
    font-size: var(--font-size-text);
    line-height: 22px; // @todo: This varable is missing in the variables file
  }
}


.TypographyCopySmall {
  font-size: var(--font-size-text);
  line-height: 20px; // @todo: This varable is missing in the variables file

  @media all and (min-width: $laptop) {
    font-size: var(--font-size-text-small);
    line-height: 20px; // @todo: This varable is missing in the variables file
  }
}
